// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dr_BF";
export var mobileAppCardTechStack__card = "dr_BG";
export var mobileAppCardTechStack__description = "dr_BJ";
export var mobileAppCardTechStack__iconsMobileDev = "dr_BK";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dr_BP";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dr_BM";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dr_BN";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dr_BL";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dr_BQ";
export var mobileAppCardTechStack__title = "dr_BH";