// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "G_f4";
export var devBenefitBussinesMobileDev = "G_f1";
export var domainExpertiseMobileDev = "G_fX";
export var mobileAppDevTechnologies = "G_fT";
export var mobileAppDevTechnologies__wrapper = "G_fV";
export var ourKeyPrinciplesMobileDev = "G_f0";
export var serviceMobileTestimonialPost = "G_fZ";
export var servicesCardBorderIconMobileDevSection = "G_fR";
export var servicesCardWithTopBorderSectionMobileApp = "G_fS";
export var servicesProcessMobileDevSection = "G_fY";
export var successStoriesMobileSection = "G_f3";
export var weBuildProjectsDifferentSection = "G_fW";
export var whyChooseServicesMobile = "G_f2";